<template>
  <v-container fluid>
    <v-file-input
      v-model="file"
      small-chips
      multiple
      accept="image/*"
      show-size
      label="Selecione as Imagens"
      @change="onChange"
    />
  </v-container>
</template>
<script>
  export default {
    data () {
      return {
        file: [],
      }
    },
    methods: {
      onChange () {
        this.$emit('onSelecionarImagens', this.file)
      },
    },
  }
</script>
