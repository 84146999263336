<template>
  <v-card>
    <v-card>
      <v-toolbar
        dark
        color="primary"
      >
        <v-toolbar-title>
          <v-icon class="white--text">
            mdi-truck-fast-outline
          </v-icon> Expedição
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          class="mx-2"
          small
          fab
          color="amber"
          @click="onSave"
        >
          <v-icon class="white--text">
            mdi-content-save
          </v-icon>
        </v-btn>
        <v-btn
          class="mx-2"
          small
          fab
          color="light-green accent-4"
          @click="onSair"
        >
          <v-icon class="white--text">
            mdi-exit-to-app
          </v-icon>
        </v-btn>
      </v-toolbar>
    </v-card>
    <v-card>
      <v-container>
        <uploadFile @onSelecionarImagens="onSelecionarImagens" />
        <v-row
          align="center"
        >
          <v-col
            v-for="(img, index) in imagens"
            :key="index"
            cols="3"
            class="d-flex child-flex"
          >
            <v-card>
              <v-img
                :lazy-src="getUrl(img)"
                max-height="300"
                :src="getUrl(img)"
                contain
                class="rounded-lg"
              />
              <v-card-actions>
                <v-btn
                  icon
                  @click="onDeleteImagem(index)"
                >
                  <v-icon>
                    mdi-trash-can
                  </v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-card>
</template>
<script>
  import pedidoRep from '../../components/services/orcamentoRepository'
  import uploadFile from '../componentes/util/uploadfile.vue'
  export default {
    components: {
      uploadFile,
    },
    props: {
      pedido: {
        type: Object,
        default: () => ({}),
      },
    },
    data () {
      return {
        pedidoSave: {},
        file: [],
        imagens: [],
      }
    },
    created () {
      this.getFilesExpedicao()
    },
    methods: {
      onSair () {
        this.$emit('onSairExpedicao')
      },
      getUrl (nomeimg) {
        return this.$store.getters.empresa.url.includes('locahost') ? this.$store.getters.empresa.url.replace('/Api/', '').replace('/api/', '') + '/src/img/expedicao/' + nomeimg : this.$store.getters.empresa.url.replace('/sfcloud/Api/', '').replace('/sfcloud/api/', '') + '/src/img/expedicao/' + nomeimg
      },
      getFilesExpedicao () {
        pedidoRep.getFileListImageExpedicao(this.pedido.pedidoFormatado.replaceAll('/', '-')).then(response => {
          if (response.data.status === 'sucesso') {
            this.imagens = response.data.dados
          }
        })
      },
      onSave () {
        var formData = new FormData()
        formData.append('orcamentoFormatado', this.pedido.pedidoFormatado)
        formData.append('cdOrcamento', this.pedido.cdOrcamento)
        for (let index = 0; index < this.imagens.length; index++) {
          formData.append('imagens', this.imagens[index])
        }
        for (let index = 0; index < this.file.length; index++) {
          formData.append('files', this.file[index])
        }
        pedidoRep.SalvarExpedicao(formData).then(response => {
          if (response.data.status === 'sucesso') {
            this.$swal({
              icon: 'success',
              text: 'Upload realizado com Sucesso!',
              confirmButtonText: 'OK',
            }).then((result) => {
              if (result.isConfirmed) this.$emit('onSucessoExpedicao')
            })
          } else if (response.data.status === 'message') {
            this.$swal({
              icon: 'warning',
              text: response.data.mensagem,
            })
          } else if (response.data.status === 'error') {
            this.$swal({
              icon: 'warning',
              text: response.data.mensagem,
            })
          }
          this.$store.commit('setSplashScreen', false)
        }).catch(error => {
          this.$store.commit('setSplashScreen', false)
          this.$swal({
            icon: 'warning',
            text: error,
          })
        })
      },
      onSelecionarImagens (file) {
        this.file = file
      },
      onDeleteImagem (index) {
        this.imagens.splice(index, 1)
      },
    },
  }
</script>
