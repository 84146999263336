import Repository from './repository'

const resource = '/transacaousuario'

export default {

  async get (dados) {
    return await Repository.get(`${resource}/${dados.usuario}/${dados.transacao}`)
  },
  async getTransacao (dados) {
    return await Repository.get(`${resource}?usuario=${dados.usuario}&transacao=${dados.transacao}`)
  },
}
