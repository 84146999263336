<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        Lista de Pedidos
        <v-spacer />
        <calendario @onDataSelecionada="onDataSelecionada" />
      </v-card-title>
      <v-data-table
        dense
        :headers="headers"
        :items="listaPedido"
      >
        <template v-slot:item.vlTotal="{ item }">
          {{ formatPrice(item.vlTotal) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <!--<v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  v-bind="attrs"
                  color="blue"
                  @click="onEditar(item)"
                  v-on="on"
                >
                  mdi-pencil-outline
                </v-icon>
              </template>
              <span>Editar</span>
            </v-tooltip>-->
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <v-list-item-title>
                  <v-row
                    align="center"
                  >
                    <v-btn
                      text
                      @click="onExpedicao(item)"
                    >
                      <v-icon
                        left
                        color="light-blue darken-4"
                      >
                        mdi-truck-fast-outline
                      </v-icon>
                      Expedição
                    </v-btn>
                  </v-row>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      v-model="dialogExpedicao"
      persistent
      max-width="800"
    >
      <modelExpedicao
        v-if="dialogExpedicao"
        :pedido="pedido"
        @onSairExpedicao="onSairExpedicao"
        @onSucessoExpedicao="onSucessoExpedicao"
      />
    </v-dialog>
  </v-container>
</template>
<script>
  import pedidoRep from '../../../components/services/orcamentoRepository'
  import transacaoRep from '../../../components/services/transacaoRepository'
  // COMPONENTES
  import modelExpedicao from '../../componentes/componente-expedicao.vue'
  import calendario from '../../componentes/util/datacalendario.vue'
  export default {
    components: {
      calendario,
      modelExpedicao,
    },
    data () {
      return {
        dataAtual: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        headers: [
          {
            text: 'Pedido',
            align: 'start',
            value: 'pedidoFormatado',
            width: 200,
          },
          {
            text: 'Vl. Total',
            align: 'end',
            value: 'vlTotal',
            width: 200,
          },
          {
            text: 'Vendedor',
            align: 'start',
            value: 'nmVendedor',
            width: 200,
          },
          { text: '', value: 'actions', width: 100, sortable: false },
        ],
        listaPedido: [],
        pedido: {},
        dialogExpedicao: false,

      }
    },
    async created () {
      this.dataSelecionada = this.formatDate(this.dataAtual)
      this.onCarregarConsulta()
      // this.getTransacao()
      if (this.$store.getters.usuario.tpusuario !== 'A') {
        await this.getTransacao()
      }
    },
    methods: {
      onDataSelecionada (data) {
        this.dataSelecionada = data
        this.onCarregarConsulta()
      },
      formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${day}-${month}-${year}`
      },
      async onCarregarConsulta () {
        this.$store.commit('setSplashScreen', true)
        await pedidoRep.getListDate(this.dataSelecionada).then(response => {
          if (response.data.status === 'sucesso') {
            this.$store.commit('setSplashScreen', false)
            this.listaPedido = response.data.dados
          } else if (response.data.status === 'mensagem') {
            this.$store.commit('setSplashScreen', false)
            this.$swal({
              icon: 'warning',
              text: response.data.dados,
            })
          } else if (response.data.status === 'error') {
            this.$store.commit('setSplashScreen', false)
            this.$swal({
              icon: 'error',
              text: response.data.messagem,
            })
          } else {
            this.$swal({
              icon: 'error',
              text: response.data.mensagemErro,
            })
            this.$store.commit('setSplashScreen', false)
          }
        }).catch(error => {
          this.$store.commit('setSplashScreen', false)
          this.$swal({
            icon: 'error',
            text: error,
          })
        })
      },
      async getTransacao () {
        this.$store.commit('setSplashScreen', true)
        var dados = {
          usuario: this.$store.getters.usuario.cdusuario,
          transacao: 'FA001FOT',
        }
        await transacaoRep.get(dados).then(response => {
          if (response.data.status === 'mensagem') {
            this.$store.commit('setSplashScreen', false)
            this.$swal({
              icon: 'warning',
              text: response.data.dados,
              confirmButtonText: 'OK',
            }).then((result) => {
              if (result.isConfirmed) this.$router.replace('/v1')
            })
          } else if (response.data.status === 'error') {
            this.$store.commit('setSplashScreen', false)
            this.$swal({
              icon: 'error',
              text: response.data.messagem,
              confirmButtonText: 'OK',
            }).then((result) => {
              if (result.isConfirmed) this.$router.replace('/v1')
            })
          } else {
            this.$store.commit('setSplashScreen', false)
          }
        }).catch(error => {
          this.$store.commit('setSplashScreen', false)
          // this.$swal({
          //   icon: 'error',
          //   text: error,
          // })
          this.$swal({
            icon: 'error',
            text: error,
            confirmButtonText: 'OK',
          }).then((result) => {
            if (result.isConfirmed) this.$router.replace('/v1')
          })
        })
      },
      formatPrice (value) {
        var val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      },
      onExpedicao (pedido) {
        this.dialogExpedicao = true
        this.pedido = pedido
      },
      onSairExpedicao () {
        this.dialogExpedicao = false
      },
      onSucessoExpedicao () {
        this.dialogExpedicao = false
      },
    },
  }
</script>
