import Repository from './repository'

const resource = '/pedido'

export default {

  getId (id) {
    return Repository.get(`${resource}/${id}`)
  },
  async getList () {
    return await Repository.get(`${resource}/getlist`)
  },
  // async getListDate (data, expedicao = 0) {
  //   return await Repository.get(`${resource}/orcamentosdia?dtIni=${data}${expedicao}`)
  // },
  async getListDate (data, expedicao = 0) {
    return await Repository.get(`${resource}/orcamentosdia?dtIni=${data}`)
  },
  async getListPedidosExpedicaoPorStatus (data, loja, status) {
    return await Repository.get(`${resource}/orcamentosexpedicaodia?loja=${loja}`)
  },
  async getListPedidosExpedicaoDevolucaoPorStatus (data, loja, status) {
    return await Repository.get(`${resource}/orcamentosexpedicaodia?loja=${loja}&status=${status}`)
  },
  async getFileListImageExpedicao (data) {
    return await Repository.get(`${resource}/orcamentosexpedicao/getfiles/${data}`)
  },
  Update (data) {
    return Repository.post(`${resource}/editar/`, data)
  },
  Delete (data) {
    return Repository.post(`${resource}/excluir/`, data)
  },
  Salvar (data) {
    return Repository.post(`${resource}/salvar/`, data)
  },
  async SalvarExpedicao (data) {
    return Repository.post(`${resource}/orcamentoexpedicao/`, data, { headers: { 'Content-Type': 'multipart/form-data' } })
  },
  async ItensPedido (data) {
    return await Repository.get(`${resource}/itens/${data}`)
  },
  async RetornaIdOrcamento (data) {
    return await Repository.get(`${resource}/retornaidorcamento?orcamento=${data}`)
  },
}
